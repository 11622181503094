iframe {
    visibility: hidden;
    margin: -5px -25px !important;
}

.radioButton {
    position: absolute;
    z-index: -1000;
}

.modalBox .MuiBox-root {
    padding-top: 20px !important;
    padding-bottom: 20px !important;
}

.MuiList-padding {
    padding-top: 0px !important;
}

.haAclf {
    padding: 0px !important
}

.privacyPolicy p {
    margin: 0px;
}

.privacyPolicy p strong {
    font-size: 24px;
}

.closeIcon {
    position: absolute;
}