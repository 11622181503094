.Quetion_bank_list_header {
    display: flex;
    justify-content: space-between;
    background: #d1cece;
    border-radius: 20px;
    padding: 10px 11px;
    align-items: center;
    margin: 0 0 8px 0;
}

.Quetion_bank_list_header_text {
    margin: 28px 0;
    font-size: 36px;
}

.Quetion_bank_list_header_text h4 {
    font-size: 18px;
    color: #fff;
    margin: 0;
}

.Quetion_bank_btn,
.Quetion_bank_btn:focus {
    margin-right: 15px;
    background-color: goldenrod;
    font-size: 14px;
    font-weight: 600;
    border: none;
    color: white;
    border-radius: 20px;
}

.gallery_select p {
    margin-right: 15px;
    background-color: goldenrod;
    font-size: 14px;
    font-weight: 600;
    border: none;
    color: white;
    border-radius: 20px;
    padding: 6px;
    cursor: pointer;
}

.Question_box {
    margin-bottom: 0px;
    padding: 10px 0 10px 10px;
    font-size: 16px;
    font-weight: 500;
    width: 100%;
    color: black;
}

.Question_box_view {
    border: 1px solid rgb(196, 189, 189);
    border-radius: 20px;
    display: flex;
    align-items: center;
}

.Options_view_box {
    margin-top: 20px;
    border: 1px solid rgb(196, 189, 189);
    padding: 8px;
    border-radius: 20px;
    display: flex;
    align-items: center;
}

.Options_view {
    color: black;
    font-size: 16px;
    font-weight: 500;
    width: 100%;
    margin: 0;
    display: flex;
    align-items: center;
    justify-content: center;
}

.class-pag {
    display: flex;
    justify-content: flex-end;
    padding-top: 15px;
    list-style: none !important;
}

.modal-content {
    border: none !important;
}

.Answer_description_box {
    background: goldenrod;
    color: #fff;
    font-weight: 600;
    padding: 6px 14px 6px 14px;
    border-radius: 20px;
}

.Answer_description_box p {
    margin: 0;
}

input[type="file"] {
    display: none !important;
}

.Question_answer_box {
    margin: 25px 12px;
}

.description_heading {
    font-size: 18px;
    margin: 10px 0;
}