.progress-0>.MuiLinearProgress-bar1Determinate {
    background-color: purple !important;
}

.progress-1>.MuiLinearProgress-bar1Determinate {
    background-color: yellow !important;
}

.progress-2>.MuiLinearProgress-bar1Determinate {
    background-color: rgb(182, 82, 98) !important;
}

.progress-3>.MuiLinearProgress-bar1Determinate {
    background-color: green !important;
}

.action_description {
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: initial;
    display: -webkit-box;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
}